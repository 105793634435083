import { Box, Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { GET_NEWS_DETAIL_QUERY_KEY, getNewsDetail } from "./api/newsScreenApi";
import { HtmlParser } from "../root/HtmlParser";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { DateTime } from "luxon";
import { Tag } from "../../components/tags/Tag";
import { Reactions } from "./components/Reactions";
import { RelatedNews } from "./components/RelatedNews";
import { SectionTitle } from "../../components/text/SectionTitle";
import { t } from "i18next";
import { useAccountData } from "../../hooks/useAccountData";
import { BreadcrumbNav } from "../../components/navigation/components/BreadcrumbNav";

type RouteParams = {
  id: string;
  title: string;
};

export default function NewsDetailsScreen() {
  const accountData = useAccountData();
  const params = useParams<RouteParams>();
  const { isLoading, data } = useQuery({
    enabled: !!accountData && !!params.id,
    queryKey: [...GET_NEWS_DETAIL_QUERY_KEY, params.id, accountData?.user?.id],
    queryFn: () => getNewsDetail(params.id ?? ""),
    refetchOnWindowFocus: false,
  });

  const isSmall = useIsSmallBreakpoint();
  if (isLoading || !accountData) {
    return (
      <Flex width="full" alignItems="center" justifyContent="center">
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  const article = data?.data.payload?.results;

  if (!article) {
    return (
      <Flex width="full" px={3}>
        <Text color="neutral.white">{t("screens.news.errorTitle")}</Text>
      </Flex>
    );
  }

  const date = DateTime.fromSQL(article.published_time);
  const formattedDate = date.toFormat("MMMM d, yyyy");

  return (
    <Flex
      width="full"
      px="12px"
      pt="42px"
      overflowY="auto"
      maxHeight="100%"
      flexDirection={isSmall ? "column" : "row"}
      justifyContent={isSmall ? "flex-start" : "center"}
      gap="42px"
    >
      <Flex
        pb="42px"
        gap={2}
        direction="column"
        maxWidth={isSmall ? "100%" : "720px"}
        flexGrow={1}
      >
        <BreadcrumbNav
          path={[
            { name: t("screens.news.title"), url: "/news" },
            {
              name: article.category?.display_name ?? "",
              url: `/news/${article.category?.slug}`,
            },
            { name: article.title, url: window.location.pathname },
          ]}
        />
        <Box>
          <Text as="h1" variant="newsTitle" mb="24px" color="dune.dune">
            {article.title}
          </Text>
          {article.category ? (
            <>
              <Link href={article.category.url}>
                <Text
                  variant="newsHeader"
                  fontSize={isSmall ? "14px" : "16px"}
                  color="blaze.blaze"
                  pb="16px"
                >
                  {article.category.display_name}
                </Text>
              </Link>
            </>
          ) : null}
          <Flex direction="row" gap="11px" alignItems="center" mb="40px">
            <Flex>
              <Text variant="newsHeader">By:&nbsp;</Text>
              <Link
                href={article.author.url}
                display="flex"
                alignContent="center"
              >
                <Text variant="newsHeader" textDecoration="underline">
                  {article.author.name}
                </Text>
              </Link>
            </Flex>

            {article.author.is_admin ? (
              <Box
                bg="transparent"
                borderRadius="full"
                border="1px solid"
                borderColor="blaze.300"
                px="8px"
              >
                <Text
                  variant="monospaceTime"
                  letterSpacing="0.5px"
                  color="dune.dune"
                >
                  Admin
                </Text>
              </Box>
            ) : null}

            <Text variant="newsHeader">|</Text>
            <Text variant="newsHeader">{formattedDate}</Text>

            {article.can_edit ? (
              <Link
                borderRadius="4px"
                padding="2px 8px 3px 8px"
                alignItems="center"
                bg="blaze.300"
                href={article.edit_url}
                height="fit-content"
                minWidth="fit-content"
                _hover={{ bg: "blaze.400", pointer: "cursor" }}
              >
                <Text variant="monospaceTime" color="black">
                  Edit
                </Text>
              </Link>
            ) : null}
          </Flex>
        </Box>
        <Flex direction="column" gap="40px" pb="42px">
          <HtmlParser htmlString={article.parsed_body ?? ""} />
          <Box h="1px" w="full" background="transparent.white.10" />
          <Flex gap="8px" direction="row" flexWrap="wrap">
            {Object.values(article.tags ?? []).map((tag) => {
              return <Tag key={tag.id} text={tag.name} />;
            })}
          </Flex>
          <Box h="1px" w="full" background="transparent.white.10" />
          <Reactions
            articleId={article.id}
            reactions={article.reactions ?? []}
          />
          <Box h="1px" w="full" background="transparent.white.10" />
          <SectionTitle>{t("screens.news.relatedNewsTitle")}</SectionTitle>
          <RelatedNews articleId={article.id} />
        </Flex>
      </Flex>
      {!isSmall ? (
        <Flex
          direction="column"
          gap="32px"
          minWidth="240px"
          position="relative"
        >
          <SectionTitle>{t("screens.news.relatedNewsTitle")}</SectionTitle>
          <RelatedNews articleId={article.id} showDividers />
        </Flex>
      ) : null}
    </Flex>
  );
}
